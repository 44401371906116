<template>
    <div>
        <div class="page-title">{{ qa.title }}</div>

        <a-table
                :rowKey="record => record.id || record.key"
                :data-source="table.data"
                :pagination="table.pagination"
                @change="tableChanged"
                :locale="{emptyText:'暂无答卷'}"
                class="members-table"
                :loading="table.loading"
        >
            <a-table-column title="#" data-index="id"></a-table-column>
            <a-table-column title="头像" data-index="avatar">
                <template slot-scope="avatar">
                    <img class="mem-avatar" :src="avatar" alt="">
                </template>
            </a-table-column>
            <a-table-column title="用户名" data-index="name">
                <template slot-scope="name,item">
                    {{ item.mark_name || item.name }}
                </template>
            </a-table-column>
            <a-table-column title="用户ID" data-index="member_id"></a-table-column>

            <a-table-column title="填写时间" data-index="created_at">
                <template slot-scope="val">
                    {{ $formatTime(val) }}
                </template>
            </a-table-column>

            <a-table-column title="操作" data-index="op">
                <template slot-scope="val,item">
                    <button class="btn" @click="activeMiniCode(item)">查看</button>
                </template>
            </a-table-column>

        </a-table>

        <img v-if="miniCode" @load="qrLoad" class="preload-img" :src="miniCode" alt="">

        <a-modal v-model="showMiniCodeModal" :footer="null" width="406px">
            <template #title v-if="detail">{{ qa.title }}</template>
            <div class="mini-qrcode-container flex flex-center">
                <small>问卷结果二维码</small>
                <br>
                <img v-if="miniCode" :src="miniCode" alt="">
                <br>
                <small>微信扫码查看用户填写的问卷</small>
            </div>
        </a-modal>

    </div>
</template>

<script>
import api from "@/repo/api";

export default {
    name: "Member",
    data() {
        return {
            qa: {},
            qaId: '',
            table: {
                loading: true,
                pagination: {
                    defaultCurrent: 1,
                    current: 1,
                    defaultPageSize: 10,
                    total: 1,
                    pageSize: 15,
                    showTotal: (total, range) => {
                        return `当前${range[0]}-${range[1]}，共 ${total} 个案例`;
                    },
                },
                data: []
            },
            query: {},
            detail: null,
            showMiniCodeModal: false,
            miniCode: undefined
        }
    },
    mounted() {
        this.qaId = this.$route.params.id

        this.query.id = this.qaId

        this.getFirstList()
    },
    methods: {
        activeMiniCode(item) {
            this.detail = item;

            this.$loading.show()

            setTimeout(() => {

            }, 50)

            this.miniCode = this.$qrcode(
                "packagesC/questionnaireDetail/questionnaireDetail?id=" + item.id,
                `问卷小程序码-${item.id}`
            );
        },
        qrLoad() {
            this.$loading.hide()
            this.showMiniCodeModal = true
        },
        getFirstList() {
            this.query.page = 1
            this.getList()
        },
        tableChanged(pagination) {
            this.query.page = pagination.current
            this.getList()
        },
        getList() {

            this.table.loading = true

            api.get('/ast-qa/members', this.query, data => {


                this.table.data = data.data.data
                this.table.pagination.current = data.data.current_page
                this.table.pagination.total = data.data.total
                this.table.pagination.pageSize = data.data.per_page

                this.table.loading = false

                this.qa = data.data.qa
            })
        },
        showQrcode(item) {
            console.log(item);
        }
    }
}
</script>

<style scoped lang="less">
.mem-avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
}

.mini-qrcode-container {
    flex-direction: column;

    img {
        width: 256px;
        height: 256px;
    }
}
</style>